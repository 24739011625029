import React from 'react'
import moment from 'moment'

const EndDateManageAccess = ({ attribute, data }) => {
    const timeConstraintActive =
        data.assignment?.assignmentDetails?.timeConstraintActive

    return (
        <>
            {!timeConstraintActive
                ? '-'
                : moment
                      .utc(data?.assignment?.assignmentDetails.endDateUtc)
                      .clone()
                      .local()
                      .format('LLL')}
        </>
    )
}

export default EndDateManageAccess
